import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Cathero from "../components/Category-hero"
import Logoimg from "../components/Clients"

import parser from "react-html-parser"
import Crsehighlight from "../components/Course-highlights"

import "../css/Media-1920-2560.css"
// import "../css/Media-1440-1919.css"
import "../css/Media-1024-1439.css"
import "../css/Media-768-1023.css"
import "../css/Media-768-1023-Landscape.css"
// import "../css/Media-481-767.css"
import "../css/Media-320-480.css"
import SEO from "../components/Seo"

import Advantage from "../components/Succeed-advantage"

import Quote from "../components/Testimonials"
import Infosecdemo from "../components/Infosecdemo"

const Infosecdata = ({ data }) => {
  let yoast = data.wordpressPage.yoast_head

  let infosecqueries = data.wordpressPage

  return (
    <>
      <Layout
        logo={data.allWordpressAcfSucceedlearnLogo.edges}
        favicon={data.allWordpressAcfSucceedlearnApple.edges}
      >
        <SEO title={{ yoast }} />
        {/* Hero Section  */}
        <section className="financial-hero securityhead" id="financial-hero">
          <Cathero
            data={infosecqueries.acf.title}
            img={
              infosecqueries.acf.image.localFile.childImageSharp.fluid
                .originalImg
            }
            tabimage={
              infosecqueries.acf.tablet_image.localFile.childImageSharp.fluid
                .originalImg
            }
            mobimage={
              infosecqueries.acf.mobile_image.localFile.childImageSharp.fluid
                .originalImg
            }
            desc={infosecqueries.acf.description}
            subtitle={infosecqueries.acf.subtitle}
          />
        </section>

        <section className="clients" id="clients">
          <Logoimg
            clientsrow={data.allWordpressAcfClientsLogos}
            title={data.wordpressPage.acf.clients_title}
          />
          <hr className="client-below" />
        </section>

        {/* Advantage Section */}
        <Advantage
          title={infosecqueries.acf.adv_title}
          img1={
            infosecqueries.acf.imageone.localFile.childImageSharp.fluid
              .originalImg
          }
          img2={
            infosecqueries.acf.imagesecond.localFile.childImageSharp.fluid
              .originalImg
          }
          img3={
            infosecqueries.acf.imagethree.localFile.childImageSharp.fluid
              .originalImg
          }
          imgdesc1={infosecqueries.acf.image1desc}
          imgdesc2={infosecqueries.acf.image2}
          imgdesc3={infosecqueries.acf.image3}
        />
        {/* <hr className="client-below" /> */}
        {/* End of Advantage Section */}
        {/* You tube Video */}
        <section className="youtubeapi" id="youtubeapi">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-3"></div>
              <div className="col-lg-6 col-md-12 text-center py-5">
                <iframe
                  width="640"
                  height="390"
                  class="responsive-iframe"
                  src={infosecqueries.acf.youtube_link}
                  frameborder="0"
                  allowfullscreen
                ></iframe>
              </div>
              <div className="col-lg-3"></div>
            </div>
          </div>
        </section>
        {/* End Of Youtube Section */}

        <section className="comprehensive" id="comprehensive">
          <div className="container-fluid">
            <div className="row info">
              <div className="col-lg-2"></div>
              <div className="col-lg-8 col-md-12 col-xs-12 col-sm-12 comp-pad">
                <div className="title-comprehensive">
                  {parser(infosecqueries.acf.infosec_course_title)}
                </div>
              </div>
              <div className="col-lg-2"></div>
            </div>

            <div className="row mb-lg-4 ">
              <div className="col-lg-1 lastdiv-bsinfosec"></div>
              <div className="col-lg-5 col-md-12   d-none d-lg-block  justify-content-center flex-column compre-img">
                <picture>
                  <source
                    media="(min-width: 650px)"
                    srcset={
                      infosecqueries.acf.infosec_best_course_image.localFile
                        .childImageSharp.fluid.originalImg
                    }
                  />
                  <source
                    media="(min-width: 465px)"
                    srcset={
                      infosecqueries.acf.infosec_best_course_image.localFile
                        .childImageSharp.fluid.originalImg
                    }
                  />
                  <img
                    src={
                      infosecqueries.acf.infosec_best_course_image.localFile
                        .childImageSharp.fluid.originalImg
                    }
                    alt="infosec"
                    style={{ width: "100%" }}
                    className="comprehensive-landingsrc"
                  />
                </picture>
              </div>

              <div className="col-lg-4 col-md-12  d-flex  flex-column comprehensive-cont">
                <div className="row top-row">
                  <div className="col-lg-10 col-md-12 mt-lg-4 row-icon-img">
                    <Link to="/Infosec-data-protection/security-awareness/">
                      <div className="compre-radius">
                        <div className="row">
                          <div className="col-lg-3  radius-border icons-mobile">
                            <div className="img-icon">
                              <img
                                src={
                                  infosecqueries.acf.infosec_course_imageicon1
                                    .localFile.childImageSharp.fluid.originalImg
                                }
                                alt={
                                  infosecqueries.acf.infosec_course_icon1_title
                                }
                              />
                            </div>
                          </div>

                          <div className="col-lg-7 col-md-12 title-text">
                            <div className="head-des">
                              {parser(
                                infosecqueries.acf.infosec_course_icon1_title
                              )}
                              {parser(
                                infosecqueries.acf
                                  .infosec_course_icon1_description
                              )}
                            </div>
                          </div>
                          <div className="col-lg-2 next">
                            <img
                              src={
                                infosecqueries.acf
                                  .infosec_course_image_link_icon.localFile
                                  .childImageSharp.fluid.originalImg
                              }
                              alt="link-icon"
                            />
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="col-lg-2"></div>
                </div>

                {/* 
                End of First Div
                Little side by side row according to design */}
                <div className="row top-row">
                  <div className="col-lg-2"></div>

                  <div className="col-lg-10 col-md-12 mt-lg-4">
                    <Link to="/Infosec-data-protection/phishing-attack-simulator-training/">
                      <div className="compre-radius">
                        <div className="row">
                          <div className="col-lg-3 radius-border">
                            <div className="img-icon">
                              <img
                                src={
                                  infosecqueries.acf.infosec_course_imageicon2
                                    .localFile.childImageSharp.fluid.originalImg
                                }
                                alt={
                                  infosecqueries.acf.infosec_course_icon2_title
                                }
                              />
                            </div>
                          </div>

                          <div className="col-lg-7 col-md-12 title-text">
                            <div className="head-des">
                              {parser(
                                infosecqueries.acf.infosec_course_icon2_title
                              )}
                              {parser(
                                infosecqueries.acf
                                  .infosec_course_icon2_description
                              )}
                            </div>
                          </div>
                          <div className="col-lg-2 next">
                            <img
                              src={
                                infosecqueries.acf
                                  .infosec_course_image_link_icon.localFile
                                  .childImageSharp.fluid.originalImg
                              }
                              alt="link-icon"
                            />
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
                {/* 
                End of Second Div
                Little side by side row according to design */}
                <div className="row top-row">
                  <div className="col-lg-10 col-md-12 mt-lg-4">
                    <Link to="/Infosec-data-protection/security-tips-for-remote-workforce/">
                      <div className="compre-radius">
                        <div className="row">
                          <div className="col-lg-3 radius-border">
                            <div className="img-icon">
                              <img
                                src={
                                  infosecqueries.acf.infosec_course_imageicon3
                                    .localFile.childImageSharp.fluid.originalImg
                                }
                                alt={
                                  infosecqueries.acf.infosec_course_icon3_title
                                }
                              />
                            </div>
                          </div>

                          <div className="col-lg-7 col-md-12 title-text">
                            <div className="head-des">
                              {parser(
                                infosecqueries.acf.infosec_course_icon3_title
                              )}
                              {parser(
                                infosecqueries.acf
                                  .infosec_course_icon3_description
                              )}
                            </div>
                          </div>
                          <div className="col-lg-2 next">
                            <img
                              src={
                                infosecqueries.acf
                                  .infosec_course_image_link_icon.localFile
                                  .childImageSharp.fluid.originalImg
                              }
                              alt="link-icon"
                            />
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="col-lg-2 "></div>
                </div>

                {/* 
                End of third Div
                Little side by side row according to design */}

                <div className="row top-row">
                  <div className="col-lg-2"></div>
                  <div className="col-lg-10  col-md-12 mt-lg-4">
                    <Link to="/Infosec-data-protection/iso27001-awareness/">
                      <div className="compre-radius">
                        <div className="row">
                          <div className="col-lg-3 radius-border">
                            <div className="img-icon">
                              <img
                                src={
                                  infosecqueries.acf.infosec_course_imageicon4
                                    .localFile.childImageSharp.fluid.originalImg
                                }
                                alt={
                                  infosecqueries.acf.infosec_course_icon4_title
                                }
                              />
                            </div>
                          </div>

                          <div className="col-lg-7 col-md-12 title-text">
                            <div className="head-des">
                              {parser(
                                infosecqueries.acf
                                  .infosec_course_icon4_title_copy
                              )}
                              {parser(
                                infosecqueries.acf
                                  .infosec_course_icon4_description
                              )}
                            </div>
                          </div>
                          <div className="col-lg-2 next">
                            <img
                              src={
                                infosecqueries.acf
                                  .infosec_course_image_link_icon.localFile
                                  .childImageSharp.fluid.originalImg
                              }
                              alt="link-icon"
                            />
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
                {/* 
                End of Fourth Div
                Little side by side row according to design */}

                <div className="row top-row">
                  <div className="col-lg-10 col-md-12 mt-lg-4">
                    <Link to="/Infosec-data-protection/pci-dss-awareness/">
                      <div className="compre-radius">
                        <div className="row">
                          <div className="col-lg-3 radius-border">
                            <div className="img-icon">
                              <img
                                src={
                                  infosecqueries.acf.infosec_course_imageicon5
                                    .localFile.childImageSharp.fluid.originalImg
                                }
                                alt={
                                  infosecqueries.acf.infosec_course_icon5_title
                                }
                              />
                            </div>
                          </div>

                          <div className="col-lg-7 col-md-12 title-text">
                            <div className="head-des">
                              {parser(
                                infosecqueries.acf.infosec_course_icon5_title
                              )}
                              {parser(
                                infosecqueries.acf
                                  .infosec_course_icon5_description
                              )}
                            </div>
                          </div>
                          <div className="col-lg-2 next">
                            <img
                              src={
                                infosecqueries.acf
                                  .infosec_course_image_link_icon.localFile
                                  .childImageSharp.fluid.originalImg
                              }
                              alt="link-icon"
                            />
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="col-lg-2 "></div>
                </div>

                {/* 
                End of Fifth Div
                Little side by side row according to design */}

                <div className="row top-row ">
                  <div className="col-lg-2"></div>
                  <div className="col-lg-10 col-md-12 mt-lg-4">
                    <Link to="/Infosec-data-protection/gdpr-and-dpa-awareness/">
                      <div className="compre-radius">
                        <div className="row">
                          <div className="col-lg-3 radius-border">
                            <div className="img-icon">
                              <img
                                src={
                                  infosecqueries.acf.infosec_course_imageicon6
                                    .localFile.childImageSharp.fluid.originalImg
                                }
                                alt={
                                  infosecqueries.acf.infosec_course_icon6_title
                                }
                              />
                            </div>
                          </div>

                          <div className="col-lg-7 col-md-12 title-text">
                            <div className="head-des">
                              {parser(
                                infosecqueries.acf.infosec_course_icon6_title
                              )}
                              {parser(
                                infosecqueries.acf
                                  .infosec_course_icon6_description
                              )}
                            </div>
                          </div>
                          <div className="col-lg-2 next">
                            <img
                              src={
                                infosecqueries.acf
                                  .infosec_course_image_link_icon.localFile
                                  .childImageSharp.fluid.originalImg
                              }
                              alt="link-icon"
                            />
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
                {/* 
                End of Sixth Div
                Little side by side row according to design */}

                <div className="row top-row">
                  <div className="col-lg-10 col-md-12 mt-lg-4">
                    <Link to="/Infosec-data-protection/ccpa-compliance-awareness/">
                      <div className="compre-radius">
                        <div className="row">
                          <div className="col-lg-3 radius-border">
                            <div className="img-icon">
                              <img
                                src={
                                  infosecqueries.acf.infosec_course_imageicon7
                                    .localFile.childImageSharp.fluid.originalImg
                                }
                                alt={
                                  infosecqueries.acf.infosec_course_icon7_title
                                }
                              />
                            </div>
                          </div>

                          <div className="col-lg-7 col-md-12 title-text">
                            <div className="head-des">
                              {parser(
                                infosecqueries.acf.infosec_course_icon7_title
                              )}
                              {parser(
                                infosecqueries.acf
                                  .infosec_course_icon7_description
                              )}
                            </div>
                          </div>
                          <div className="col-lg-2 next">
                            <img
                              src={
                                infosecqueries.acf
                                  .infosec_course_image_link_icon.localFile
                                  .childImageSharp.fluid.originalImg
                              }
                              alt="link-icon"
                            />
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="col-lg-2 "></div>
                </div>

                {/* 
                End of Seventh Div
                Little side by side row according to design */}

                <div className="row top-row">
                  <div className="col-lg-2"></div>
                  <div className="col-lg-10 col-md-12 mt-lg-4">
                    <Link to="/Infosec-data-protection/hipaa-compliance-awareness/">
                      <div className="compre-radius">
                        <div className="row">
                          <div className="col-lg-3 radius-border">
                            <div className="img-icon">
                              <img
                                src={
                                  infosecqueries.acf.infosec_course_imageicon8
                                    .localFile.childImageSharp.fluid.originalImg
                                }
                                alt={
                                  infosecqueries.acf.infosec_course_icon8_title
                                }
                              />
                            </div>
                          </div>

                          <div className="col-lg-7 col-md-12 title-text">
                            <div className="head-des">
                              {parser(
                                infosecqueries.acf.infosec_course_icon8_title
                              )}
                              {parser(
                                infosecqueries.acf
                                  .infosec_course_icon8_description
                              )}
                            </div>
                          </div>
                          <div className="col-lg-2 next">
                            <img
                              src={
                                infosecqueries.acf
                                  .infosec_course_image_link_icon.localFile
                                  .childImageSharp.fluid.originalImg
                              }
                              alt="link-icon"
                            />
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
                {/* 
                End of 8th Div
                Little side by side row according to design */}

                <div className="row top-row">
                  <div className="col-lg-10 col-md-12 mt-lg-4">
                    <Link to="/Infosec-data-protection/ferpa-compliance-awareness/">
                      <div className="compre-radius last-div">
                        <div className="row">
                          <div className="col-lg-3 radius-border">
                            <div className="img-icon">
                              <img
                                src={
                                  infosecqueries.acf.infosec_course_imageicon9
                                    .localFile.childImageSharp.fluid.originalImg
                                }
                                alt={
                                  infosecqueries.acf.infosec_course_icon9_title
                                }
                              />
                            </div>
                          </div>

                          <div className="col-lg-7 col-md-12 title-text">
                            <div className="head-des">
                              {parser(
                                infosecqueries.acf.infosec_course_icon9_title
                              )}
                              {parser(
                                infosecqueries.acf
                                  .infosec_course_icon9_description
                              )}
                            </div>
                          </div>
                          <div className="col-lg-2 next">
                            <img
                              src={
                                infosecqueries.acf
                                  .infosec_course_image_link_icon.localFile
                                  .childImageSharp.fluid.originalImg
                              }
                              alt="link-icon"
                            />
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="col-lg-2"></div>
                </div>

                {/* 
                End of 9th Div
                Little side by side row according to design */}
              </div>
              <div className="col-lg-2 lastdiv-bsinfosec"></div>
            </div>
          </div>
        </section>

        <hr style={{ margin: 0 }} />
        <section className="price" id="price">
          <div className="container">
            <div className="row">
              {/* <div class="col-md-3 col-lg-3 "></div> */}
              <div class="col-md-12 col-lg-12">
                <div className="row price-row">
                  <div className="col-lg-6 col-md-12 mobile-price">
                    <h4>Best Price Guarantee!</h4>
                    <p>
                      Take the entire set of courses at an incredible price!
                    </p>

                    <div className="discuss-btn">
                      <Link to="/contact#schedule">
                        <button className="btn">Let's discuss!</button>
                      </Link>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12 price-img">
                    <img
                      src="https://res.cloudinary.com/dhrgc7mqu/image/upload/v1606913282/succeedlearn/lo6nei4jwahynldkhpk2.png"
                      alt="pricing"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="award" id="award">
          <div className="container-fluid  pl-lg-0 pr-lg-0">
            <div className="row">
              <div className="col-lg-2"></div>
              <div className="col-lg-8 col-md-12 text-center title">
                <h3>Award Winning and Certified Courses</h3>
              </div>
              <div className="col-lg-2"></div>
            </div>

            <div className="row">
              <div className="col-lg-4"></div>
              <div className="col-lg-4 col-md-12 text-center">
                <div className="row pt-lg-3 pb-lg-3 award-center">
                  <div className="col-lg-6 col-md-6 line-border">
                    <img
                      src="https://res.cloudinary.com/dhrgc7mqu/image/upload/v1606914797/succeedlearn/t8infd7th6fjfsycjpjg.png"
                      alt="cyber"
                    />
                  </div>

                  <div className="col-lg-6 col-md-6 cpd">
                    <img
                      src="https://res.cloudinary.com/dhrgc7mqu/image/upload/v1606914797/succeedlearn/dnahg4xfe3xj4ka8v2zi.png"
                      alt="cpd"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-4"></div>
            </div>
          </div>
        </section>
        <hr style={{ margin: 0 }} />

        {/* Course highlights */}

        <section className="highlights" id="highlights">
          <Crsehighlight
            section={"highlights"}
            h3title={"title-financial-highlights"}
            img1={
              infosecqueries.acf.crse_highlights1.localFile.childImageSharp
                .fluid.originalImg
            }
            crsetitle1={
              infosecqueries.acf.crse_highlights1_title_and_description
            }
            img2={
              infosecqueries.acf.crse_highlights2.localFile.childImageSharp
                .fluid.originalImg
            }
            crsetitle2={
              infosecqueries.acf.crse_highlights2_title_and_description
            }
            img3={
              infosecqueries.acf.crse_highlights3.localFile.childImageSharp
                .fluid.originalImg
            }
            crsetitle3={
              infosecqueries.acf.crse_highlights3_title_and_description
            }
            img4={
              infosecqueries.acf.crse_highlights4.localFile.childImageSharp
                .fluid.originalImg
            }
            crsetitle4={
              infosecqueries.acf.crse_highlights4_title_and_description
            }
            img5={
              infosecqueries.acf.crse_highlights5.localFile.childImageSharp
                .fluid.originalImg
            }
            crsetitle5={
              infosecqueries.acf.crse_highlights5_title_and_description
            }
            img6={
              infosecqueries.acf.crse_highlights6.localFile.childImageSharp
                .fluid.originalImg
            }
            crsetitle6={
              infosecqueries.acf.crse_highlights6_title_and_description
            }
            img7={
              infosecqueries.acf.crse_highlights7.localFile.childImageSharp
                .fluid.originalImg
            }
            crsetitle7={
              infosecqueries.acf.crse_highlights7_title_and_description
            }
            img8={
              infosecqueries.acf.crse_highlights8.localFile.childImageSharp
                .fluid.originalImg
            }
            crsetitle8={
              infosecqueries.acf.crse_highlights8_title_and_description
            }
            img9={
              infosecqueries.acf.crse_highlights9.localFile.childImageSharp
                .fluid.originalImg
            }
            crsetitle9={
              infosecqueries.acf.crse_highlights9_title_and_description
            }
          />
        </section>

        <hr style={{ margin: 0, borderTop: "2px solid #a8a8a8" }} />

        <section className="testimonials" id="testimonials">
          <Quote
            testimonialtitle={"What our clients have to say"}
            title={"Roja Puppala "}
            subtitle={"Learning & Development Specialist"}
            description={
              "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
            }
          />
        </section>

        <section className="infosec-demo" id="infosec-demo">
          <Infosecdemo />
        </section>

        <section className="course-domains" id="course-domains">
          <div className="container">
            <div className="row">
              <div className="col-md-3"></div>
              <div className="col-md-6 title">
                <h5>Our Other Course Domains</h5>
              </div>
              <div className="col-md-3"></div>
            </div>

            <div className="row py-3">
              <div className="col-lg-4 col-md-12 crs-card">
                <div className="card" style={{ width: "18rem" }}>
                  <Link to="/Harassment-discrimination-courses">
                    <img
                      className="card-img-top"
                      src="https://res.cloudinary.com/dhrgc7mqu/image/upload/v1606990669/succeedlearn/ufwaunp0cguzwslnslvn.png"
                      alt="infosec"
                      style={{ margin: 0 }}
                    />
                    <div className="card-body card-titledesc1">
                      <p className="card-text">
                        Discrimination & Harassment Prevention
                      </p>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 col-md-12 crs-card">
                <Link to="/financial-compliance/">
                  <div className="card" style={{ width: "18rem" }}>
                    <img
                      className="card-img-top"
                      src="https://res.cloudinary.com/dhrgc7mqu/image/upload/v1606990669/succeedlearn/h7vqfalfwttrez5nnwmq.png"
                      alt="infosec"
                      style={{ margin: 0 }}
                    />
                    <div className="card-body card-titledesc2">
                      <p className="card-text">Financial Crime Prevention</p>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-lg-4 col-md-12 crs-card">
                <Link to="/health-and-safety-courses/">
                  <div className="card" style={{ width: "18rem" }}>
                    <img
                      className="card-img-top"
                      src="https://res.cloudinary.com/dhrgc7mqu/image/upload/v1606990669/succeedlearn/qdrqit2blcwwvnv6p2qp.png"
                      alt="infosec"
                      style={{ margin: 0 }}
                    />
                    <div className="card-body card-titledesc3">
                      <p className="card-text">Health & Safety</p>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  )
}

export default Infosecdata

export const infosecquery = graphql`
  {
    allWordpressAcfSucceedlearnLogo {
      edges {
        node {
          acf {
            logo_image_ {
              localFile {
                childImageSharp {
                  fluid {
                    originalImg
                  }
                }
              }
            }
          }
        }
      }
    }
    allWordpressAcfSucceedlearnApple {
      edges {
        node {
          acf {
            logo_image_ {
              localFile {
                childImageSharp {
                  fluid {
                    originalImg
                  }
                }
              }
            }
          }
        }
      }
    }

    wordpressPage(title: { eq: "Infosec and Data Protection Courses" }) {
      acf {
        youtube_link
        clients_title
        title
        subtitle
        description
        image {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }

        tablet_image {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }

        mobile_image {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }

        infosec_course_imageicon1 {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        infosec_course_icon1_title
        infosec_course_icon1_description

        adv_title
        image1desc
        imageone {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
          alt_text
        }
        image2
        imagesecond {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
          alt_text
        }

        image3
        imagethree {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
          alt_text
        }

        infosec_best_course_image {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        infosec_course_title
        infosec_course_imageicon1 {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        infosec_course_icon1_title
        infosec_course_icon1_description

        infosec_course_imageicon2 {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        infosec_course_icon2_title
        infosec_course_icon2_description

        infosec_course_imageicon3 {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        infosec_course_icon3_title
        infosec_course_icon3_description

        infosec_course_imageicon4 {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        infosec_course_icon4_title_copy
        infosec_course_icon4_description

        infosec_course_imageicon5 {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        infosec_course_icon5_title
        infosec_course_icon5_description

        infosec_course_imageicon6 {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        infosec_course_icon6_title
        infosec_course_icon6_description

        infosec_course_imageicon7 {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        infosec_course_icon7_title
        infosec_course_icon7_description

        infosec_course_imageicon8 {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        infosec_course_icon8_title
        infosec_course_icon8_description

        infosec_course_imageicon9 {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        infosec_course_icon9_title
        infosec_course_icon9_description

        infosec_course_image_link_icon {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }

        crse_highlights1_title_and_description
        crse_highlights1 {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }

        crse_highlights2_title_and_description
        crse_highlights2 {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }

        crse_highlights3_title_and_description
        crse_highlights3 {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }

        crse_highlights4_title_and_description
        crse_highlights4 {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }

        crse_highlights5_title_and_description
        crse_highlights5 {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }

        crse_highlights6_title_and_description
        crse_highlights6 {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }

        crse_highlights7_title_and_description
        crse_highlights7 {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }

        crse_highlights8_title_and_description
        crse_highlights8 {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }

        crse_highlights9_title_and_description
        crse_highlights9 {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
      }
      yoast_head
    }

    allWordpressAcfClientsLogos {
      edges {
        node {
          acf {
            client_logo_images {
              localFile {
                childImageSharp {
                  fluid {
                    originalImg
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
