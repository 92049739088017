import React from "react"
import { Link } from "gatsby"

import "../css/infosecdemo.css"
const Infosecdemo = () => {
  return (
    <div className="container-fluid infosec-bg-grey ">
      <div className="row bg-color">
        <div className="col-md-2"></div>
        <div className="col-md-8">
          <div className="row">
            <div className="col-lg-6 col-md-12 schedule-infosec-demo">
              <h5>
                See how Succeed can <br />
                enable Security Awareness <br />
                for your organization
              </h5>
              <div className="infosec-demobtn">
                <Link to="/contact#schedule">
                  <button className="infosec-demo-btn">Schedule a Demo</button>
                </Link>
              </div>
            </div>

            <div className="col-lg-6 col-md-12">
              <img
                src="https://res.cloudinary.com/dhrgc7mqu/image/upload/v1606989479/succeedlearn/l1grd2eddhtsarzd47ex.png"
                alt="succeed-works"
              />
            </div>
          </div>
        </div>
        <div className="col-md-2"></div>
      </div>
    </div>
  )
}

export default Infosecdemo
